import React from "react";
import Seo from "../components/seo";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { Container, Row, Col, Button } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import BackgroundImage from 'gatsby-background-image';
import Slider from 'react-slick';
import { scroller } from 'react-scroll';

import SaleProductsList from "../components/SaleProduct/SaleProductsList";
import SaleProductsGroup from "../SaleProduct/SaleProductsGroup";

import advice from '../images/homepage-new/icons/advice.svg';
import balance from '../images/homepage-new/balance.svg';
import diet from '../images/homepage-new/diet.svg';
import training from '../images/homepage-new/training.svg';
import quotemark from '../images/homepage-new/quotemark.svg';
import appleBadge1 from '../images/homepage-new/apple-badge1.svg';
import appleBadge2 from '../images/homepage-new/apple-badge2.svg';
import calendar from '../images/homepage-new/icons/calendar.svg';
import changeMeal1 from '../images/homepage-new/icons/change meal-1.svg';
import changeMeal from '../images/homepage-new/icons/change-meal.svg';
import changeDish from '../images/homepage-new/icons/change-dish.svg';
import dietitian from '../images/homepage-new/icons/dietitian.svg';
import dumbbell from '../images/homepage-new/icons/dumbbell.svg';
import homeGym from '../images/homepage-new/icons/home-gym.svg';
import intensivity from '../images/homepage-new/icons/intensivity.svg';
import list from '../images/homepage-new/icons/list.svg';
import menu from '../images/homepage-new/icons/menu.svg';
import recipe from '../images/homepage-new/icons/recipe.svg';
import trainer from '../images/homepage-new/icons/trainer.svg';
import weightScale from '../images/homepage-new/icons/weight-scale.svg';
import workoutWithAnn from '../images/homepage-new/icons/workout-with-ann.svg';
import workout from '../images/homepage-new/icons/workout.svg';

import arrow from '../images/homepage-new/arrow.svg';

import Waves from '../components/waves';

import video from '../images/homepage-new/anka.mp4';
import video2 from '../images/homepage-new/anka2.mp4'

const Page = ({ data }) => {

    const bgImage = data.map.childImageSharp.fluid;

    const productsGroups = [
        new SaleProductsGroup(
            'landing:main:dietAndWorkout',
            'Dieta + trening',
            'Dostęp do planu diety i treningów.',
            'blue'
        ),
        new SaleProductsGroup(
            'landing:main:diet',
            'Dieta',
            'Dostęp tylko do planu diety.',
            'red'
        ),
        new SaleProductsGroup(
            'landing:main:workout',
            'Trening',
            'Dostęp tylko do treningów.',
            'black'
        ),
    ];

    const slider = React.createRef();

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                },
            },
        ],
    };

    const handlePrev = () => {
        slider.current.slickPrev();
    }

    const handleNext = () => {
        slider.current.slickNext();
    }

    return (
        <Layout page="homepage" showMenu={true}>
            <Seo />
            <div className="bg-cyan">
                <section className="hp1">
                    <Waves />
                    <div className="hero-img">
                        <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} alt=""/>
                    </div>
                    <Container>
                        <Row>
                            <Col md="5">
                                <h1>Stań się najlepszą wersją siebie</h1>
                                <p className="white">Treningi na własnych zasadach, indywidualnie dopasowana dieta i codzienna dawka relaksu zawsze przy Tobie. W Twoim telefonie!</p>

                                <GatsbyImage
                                    image={data.heroMobile.childImageSharp.gatsbyImageData}
                                    className="d-md-none"
                                    style={{marginLeft: '-15px', marginRight: '-15px'}} 
                                    alt=""
                                    />

                                <Button
                                    color="white"
                                    className="mt-4"
                                    onClick={() =>
                                        scroller.scrollTo('buy', {
                                            duration: 1000,
                                            smooth: true,
                                            offset: -60,
                                        })
                                    }
                                >
                                    Kup dietę i treningi
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="hp2">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="white-box">
                                    <img src={diet} className="mb-4" alt="" />
                                    <h4>Dieta</h4>
                                    <p>Indywidualnie dopasowana dieta i 3 lub 5 prostych w przygotowaniu posiłków</p>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="white-box">
                                    <img src={training} className="mb-4" alt="" />
                                    <h4>Treningi</h4>
                                    <p>Różnorodne plany treningowe przygotowane przez najlepszych trenerów</p>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="white-box">
                                    <img src={balance} className="mb-4" alt="" />
                                    <h4>Balans</h4>
                                    <p>Codzienna dawka relaksu i dbania o swoją wewnętrzną równowagę</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="hp3">
                    <Waves />
                    <Container>
                        <div className="ania">
                            <GatsbyImage image={data.ania.childImageSharp.gatsbyImageData} alt=""/>
                        </div>

                        <Row>
                            <Col md={{size: 8, offset: 4}}>
                                <div className="white-box text-left">
                                    <img src={quotemark} alt="" />
                                    <p>Zdrowy i szczęśliwy styl życia kształtowany jest dzięki odpowiedniej diecie, treningowi i codziennemu dbaniu o wewnętrzną równowagę. Teraz o wszystkie te elementy możesz zatroszczyć się przy pomocy jednej aplikacji! </p>
                                    <p>Walcz o siebie każdego dnia. Uda się!</p>
                                    <h4 className="text-right">Anna Lewandowska</h4>
                                </div>
                            </Col>
                        </Row>

                        
                    </Container>
                </section>
            </div>

            <section className="hp4">
                <Container className="text-center">
                    <BackgroundImage
                        Tag="section"
                        fluid={bgImage}
                        className="bgimg"
                    >
                        <h2 className="mb-md-5">Wyróżnienia w <span className="pink">24 krajach</span></h2>
                        <Row>
                            <Col md={{size: 6, offset: 3}}>
                                <p className="mb-md-5">Wysokie oceny od użytkowników, idealna jakość nagrań treningów oraz doprecyzowane jadłospisy diet pozwoliły Diet &amp; Training by Ann otrzymać w zeszłym roku kilkadziesiąt wyróżnień od firmy Apple.</p>    
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <img src={appleBadge1} className="img-fluid mb-4 mb-md-0" alt="" />
                            </Col>
                            <Col md="6">
                                <img src={appleBadge2} className="img-fluid" alt="" />
                            </Col>
                        </Row>
                    </BackgroundImage>
                </Container>
            </section>

            <section className="hp5 py-0">
                <Container>
                    <Row className="align-items-center">
                        <Col md="6">
                            <GatsbyImage
                                image={data.foodPhoto.childImageSharp.gatsbyImageData}
                                className="img-left" alt=""/>
                        </Col>
                        <Col md={{size: 5, offset: 1}}>
                            <h2>DIETA SZYTA<br/><span className="pink">NA MIARĘ</span></h2>
                            <p>Dzięki opcjom indywidualnego dopasowania szybko osiągniesz upragnione efekty, a do tego będziesz jeść wyłącznie takie potrawy, na które masz ochotę!</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp6 py-0">
                <Container>
                    <Row>
                        <Col xs={{order: 2}} md={{size: 5, order: 1}}>
                            <h2>Jesz tak, <span className="pink">jak lubisz</span></h2>
                            <p>Każdy z nas jest inny. Mamy odmienne upodobania żywieniowe, przyzwy­czajenia i każdy z nas toleruje inne grupy produ­któw. Dlatego w aplikacji znajdziesz propozycje kilku wariantów diety i ułatwiające gotowanie filmiki z przepisami!</p>

                            <ul className="ullist">
                                <li>
                                    <h4>Dieta klasyczna (5 posiłków)</h4>
                                    <p>
                                        do wyboru na 3 poziomach:<br/><br/>
                                        <strong>Dobry Start</strong><br/>
                                        wyklucza pszenicę  i mleko krowie<br/>
                                        <strong>Droga do Mistrzostwa</strong><br/>
                                        wyklucza gluten i nabiał krowi<br/>
                                        <strong>Wyzwanie Wojowniczki</strong><br/>
                                        wyklucza gluten i laktozę
                                    </p>
                                </li>
                                <li>
                                    <h4>Dieta klasyczna (3 posiłki)</h4>
                                    <p>Wyklucza pszenicę i jej pochodne oraz mleko krowie. Wybierz ją, jeśli chcesz spędzać w kuchni minimum czasu.</p>
                                </li>
                                <li>
                                    <h4>Dieta wegetariańska (5 posiłków)</h4>
                                    <p>Wyklucza mięso, ryby i owoce morza, pszenicę i jej pochodne oraz mleko krowie. Uwzględnia sery, jaja i nasiona roślin strączkowych.</p>
                                </li>
                            </ul>
                               
                            <Button
                                color="pink"
                                className="mt-3 w-75"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Kup dietę i treningi
                            </Button>
                        </Col>
                        <Col xs={{size: 12, order: 1}} md={{size: 6, offset: 1, order: 2}}>
                            <div className="img-right">
                                <Waves />
                                <GatsbyImage
                                    image={data.phone2.childImageSharp.gatsbyImageData}
                                    className="d-none d-md-block" alt=""/>
                                <GatsbyImage
                                    image={data.phone2Mobile.childImageSharp.gatsbyImageData}
                                    className="d-md-none" alt=""/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row>
                        <Col xs={{size: '12', order: '2'}} md={{size: '6', order: '1'}}>
                            <GatsbyImage
                                    image={data.threeMeals.childImageSharp.gatsbyImageData} alt="" className="mt-4 mt-md-0"/>
                            <Container className="text-center">
                               <Button
                                color="pink"
                                className="mt-5 d-md-none"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                                >
                                Kup dietę i treningi
                            </Button> 
                        </Container>
                        </Col>
                        <Col xs={{size: '12', order: '1'}} md={{size: '6', order: '2'}} className="text-center text-md-left">
                            <span className="tag tag-gray">nowość 2021</span>
                            <h2><span className="pink">Teraz Ty <br className="d-none d-md-block"/> wybierasz</span>, ile <br className="d-none d-md-block"/> czasu chcesz spędzić w kuchni!</h2>
                            <p className="pt-3">Jeśli zależy Ci na przygotowaniu posiłków w najkrótszym możliwym czasie, mamy dla Ciebie nowość! Dieta Diet by Ann Smart, która działa tak samo dobrze, jak dieta w wersji klasycznej, ale zawiera 3 posiłki dziennie zamiast 5. Ten wariant diety jest najłatwiejszy do stosowania, ale nadal wspiera w pełni działanie Trójkąta Mocy!</p>
                            <Button
                                color="pink"
                                className="d-none d-md-block mt-5"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                                >
                                Kup dietę i treningi
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp7 text-center pt-0">
                <Container>
                    <h2 className="mb-md-5">a poza tym...</h2>
                    <Row>
                        <Col xs="6" md="3">
                            <img src={changeDish} alt="" />
                            <p className="big">Wymiana dań</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={list} alt="" />
                            <p className="big">Wygodna<br/>lista zakupów</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={recipe} alt="" />
                            <p className="big">8 620 przepisów</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={dietitian} alt="" />
                            <p className="big">Opieka dietetyków<br/>bez limitu</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs="6" md="3">
                            <img src={changeMeal} alt="" />
                            <p className="big">Wymiana<br/>składników</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={changeMeal1} alt="" />
                            <p className="big">Diety z 3 lub 5 <br/>posiłkami dziennie</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={weightScale} alt="" />
                            <p className="big">Śledzenie<br/>pomiarów</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={menu} alt="" />
                            <p className="big">Krótkie<br/>video-przepisy</p>
                        </Col>
                    </Row>
                </Container> 
            </section>

            <section className="hp8 text-center">
                <Waves />
                <Container>
                    <h2>DOŚWIADCZENI<br/>trenerzy personalni</h2>
                </Container>
                <div className="px-5">
                    <GatsbyImage
                        image={data.trainers.childImageSharp.gatsbyImageData}
                        style={{maxWidth: '1400px', margin: '0 auto'}}
                        alt="" />
                </div>
                <Container>
                    <Row>
                        <Col md={{size: 8, offset: 2}}>
                            <div className="white-box">
                                <p className="mb-0">Profesjonalni, kompetentni, pełni pasji. Z zaangażowaniem dzielący się swoją wiedzą i pozytywną energią. Trening pod ich okiem to <strong>gwarancja wymarzonych efektów i dobrej zabawy!</strong></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp9">
                <Container className="text-center">
                    <Row>
                        <Col md={{size: 8, offset: 2}}>
                            <h2 className="mb-md-5">Ponad <span className="pink">90 planów treningowych</span></h2>
                            <p>o różnym poziomie trudności czeka na Ciebie w Diet &amp; Training by Ann.  Aby zmieścić te 4050 indywidualnych dni treningowych  na płytach DVD,  potrzebowałabyś ich aż... 1450! Aplikacja wystarczy jedna.</p>
                        </Col>
                    </Row>
                </Container>

                

                <div className="slideshow mt-4">
                    <div className="line1">
                        <GatsbyImage image={data.programsA.childImageSharp.gatsbyImageData} className="" alt=""/>
                        <GatsbyImage image={data.programsA.childImageSharp.gatsbyImageData} className="" alt=""/>
                    </div>
                    <div className="line2">
                        <GatsbyImage image={data.programsB.childImageSharp.gatsbyImageData} className="" alt=""/>
                        <GatsbyImage image={data.programsB.childImageSharp.gatsbyImageData} className="" alt=""/>
                    </div>
                </div>

                <Container className="text-center">
                    <Button
                        color="pink"
                        className="mt-4"
                        onClick={() =>
                            scroller.scrollTo('buy', {
                                duration: 1000,
                                smooth: true,
                                offset: -60,
                            })
                        }
                    >
                        Kup dietę i treningi
                    </Button>
                </Container>
            </section>

            <section className="hp10">
                <Waves />
                <Container>
                    <h2 className="text-center">POZNAJ <br className="d-md-none" />SWOICH TRENERÓW</h2>

                    
                    <div className="slider-container">
                        
                        <Slider ref={slider} {...settings}>
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage image={data.anna.childImageSharp.gatsbyImageData} alt=""/>
                                        <h4>Anna Lewandowska</h4>
                                        <p>Treningi funkcjonalne</p>
                                    </div>
                                    <p>Wielokrotna medalistka mistrzostw świata, Europy i Polski w karate tradycyjnym. Była reprezentantka Polski w karate. Dyplomowana trenerka fitness i TRX. Z wykształcenia manager sportowy i dietetyk.</p>
                                </div>
                            </div>
        
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage image={data.jj.childImageSharp.gatsbyImageData} alt=""/>
                                        <h4>Joanna Jędrzejczyk</h4>
                                        <p>Treningi z elementami sztuk walki</p>
                                    </div>
                                    <p>Reprezentantka Polski w boksie, kick-boxingu, muay thai oraz mieszanych sztukach walki (MMA). Trzykrotna zawodowa mistrzyni świata. Pierwsza Polka w prestiżowej organizacji Ultimate Fighting Championship.</p> 
                                </div>
                            </div>
                            
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage image={data.mariola.childImageSharp.gatsbyImageData} alt=""/>
                                        <h4>Mariola Bojarska - Ferenc</h4>
                                        <p>Treningi dla dojrzałych</p>
                                    </div>
                                    <p>Absolwentka Akademii Wychowania Fizycznego w Warszawie. Była członkini kadry narodowej w gimnastyce artystycznej. Instruktorka fitness z wieloletnim doświadczeniem, członkini najważniejszych światowych organizacji fitness. Swoje treningi kieruje przede wszystkim do osób dojrzałych.</p>
                                </div>
                            </div>
        
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage image={data.ewelina.childImageSharp.gatsbyImageData} alt=""/>
                                        <h4>Ewelina Godlewska</h4>
                                        <p>Yoga</p>
                                    </div>
                                    <p>Dyplomowana nauczycielka yogi z ponad 10-letnim doświadczeniem. Z wykształcenia psycholog i instruktor rekreacji ruchowej o specjalności ćwiczenia psychofizyczne w oparciu o system hatha yogi.</p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage image={data.kuba.childImageSharp.gatsbyImageData} alt=""/>
                                        <h4>Jakub Głąb</h4>
                                        <p>Treningi dla mężczyzn i par</p>
                                    </div>
                                    <p>Mistrz Polski w Fitness Sylwetkowym, wielokrotny Mistrz Polski, Europy i Świata w Karate Tradycyjnym. Z zawodu trener personalny dietetyk, instruktor samoobrony i fitness, certyfikowany trener TRX.</p>
                                </div>
                            </div>
                        </Slider>
                        <button className="slider-btn prev" onClick={handlePrev}><img src={arrow} className='rotate180deg' alt="wstecz" /></button>
                        <button className="slider-btn next" onClick={handleNext}><img src={arrow} alt="dalej" /></button>
                    </div>
                </Container>
            </section>

            <section className="hp11">
                <Container>
                    <Row>
                        <Col md="6">
                            <h2><span className="pink">Twoja</span> aplikacja</h2>
                            <ul className="ullist">
                                <li>
                                    <h4>Plany treningowe zawsze pod ręką</h4>
                                    <p>Nieważne gdzie akurat jesteś i ile masz wolnego czasu. Z aplikacją możesz ćwiczyć kiedy i jak długo chcesz. Twój trening, Twoje zasady!</p>
                                </li>
                                <li>
                                    <h4>Dopasuj efekty do swoich potrzeb</h4>
                                    <p>Diet &amp; Training by Ann daje Ci zaawansowane możliwości indywidualnego dopasowania, których nie umożliwia żadna płyta DVD.</p>
                                </li>
                                <li>
                                    <h4>Sprawdzone treningi dla kobiet w trakcie i po ciąży, mężczyzn, par, osób dojrzałych</h4>
                                    <p>Wybierasz taki program,  jaki najlepiej odpowiada Twoim aktualnym potrzebom. Bo przecież one cały czas się zmieniają!</p>
                                </li>
                                <li>
                                    <h4>Ćwicz z Apple Watch</h4>
                                    <p>Diet &amp; Training by Ann dostępne również w wersji dla zegarków Apple Watch. To idealne wsparcie Twojej aplikacji!</p>
                                </li>
                            </ul>
                        </Col>
                        <Col md="6">
                            <GatsbyImage image={data.phones2.childImageSharp.gatsbyImageData} alt=""/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp12">
                <Waves />
                <Container>
                    <h2 className="text-center mb-5">Który trening dziś <br/>wybierzesz?</h2>
                    <GatsbyImage
                        image={data.programs2.childImageSharp.gatsbyImageData}
                        alt=""
                        className="d-none d-md-block"
                         />
                    <GatsbyImage
                        image={data.programs2Mobile.childImageSharp.gatsbyImageData}
                        alt=""
                        className="d-md-none"
                         />
                </Container>
            </section>

            <section className="hp13">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
                            <video
                                className="video img-fluid"
                                aria-hidden="true"
                                muted
                                autoPlay
                                playsInline
                                loop
                            >
                                <source src={video} type="video/mp4" />
                            </video>
                        </Col>
                        <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}}>
                            <h2><span className="pink">Efektywnie</span><br/>i efektownie</h2>
                            <p className="mb-5">Dołożyliśmy wszelkich starań, żeby Twój domowy trening był tak samo skuteczny i pełen mocy, jak ten prowadzony w klubie fitness czy na siłowni.</p>

                            <ul className="ullist">
                                <li><h4>Wysoka jakość nagrań i instrukcje głosowe do ćwiczeń</h4></li>
                                <li><h4>Kalendarz Twoich treningów</h4></li>
                                <li><h4>Połącz się z Apple Music lub Spotify, by korzystać z motywacyjnych playlist w trakcie treningów</h4></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp14">
                <Waves />
                <Container>
                    <Row className="align-items-center">
                        
                        <Col md={{size: 6}}>
                            <span className="tag">Nowa funkcja</span>
                            <h2 className="mb-5">Śledź swoje postępy</h2>
                            <ul className="ullist">
                                <li>
                                    <h4>Pochwal się swoją aktualną passą</h4>
                                    <p>Dzielenie się osiągnięciami z otoczeniem pozwala na utrzymywanie motywacji do dalszego działania na wysokim poziomie.</p>
                                </li>
                                <li>
                                    <h4>Sprawdź ile drogi już za Tobą</h4>
                                    <p>Trzymaj rękę na pulsie i na bieżąco obserwuj, jak każdy trening przybliża Cię do osągnięcia upragnionego celu.</p>
                                </li>
                                <li>
                                    <h4>Monitoruj masę ciała i pomiary</h4>
                                    <p>Świadomość zmian, które zachodzą w Twoim ciele podczas stosowania diety i/lub treningów jest bardzo ważna.</p>
                                </li>
                            </ul>
                            <Button
                                color="white"
                                className="mt-3"
                                onClick={() =>
                                    scroller.scrollTo('buy', {
                                        duration: 1000,
                                        smooth: true,
                                        offset: -60,
                                    })
                                }
                            >
                                Kup dietę i treningi
                            </Button>
                        </Col>
                        <Col xs={{size: 8, offset: 2}} md={{size: 4, offset: 1}}>
                            <GatsbyImage
                                image={data.profile.childImageSharp.gatsbyImageData}
                                className="mt-5 mt-md-0" alt=""/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp15 pb-0">
                <Container>
                    <Row className="align-items-center">
                        <Col xs="12" md={{size: 6, order: 2}} className="text-center text-md-left">
                            <h2><span className="pink py-3">Nowość!</span><br/>trenuj z <span className="pink">apple watch</span></h2>
                            <p>Dzięki wersji Diet&Training by Ann dostępnej na zegarki Apple Watch sprawdzaj na bieżąco informacje podczas treningu - aktualny puls i liczbę spalonych kalorii - wszystko na Twoim nadgarstku.</p>
                            <p>Śledzenie treningów jest dzięki temu jeszcze prostsze!</p>
                        </Col>
                        <Col xs="12" md={{size: 6, order: 1}}>
                        <video
                                className="video img-fluid"
                                aria-hidden="true"
                                muted
                                autoPlay
                                playsInline
                                loop
                            >
                                <source src={video2} type="video/mp4" />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp16 text-center">
                <Container>
                    <h2 className="mb-5">a poza tym...</h2>
                    <Row>
                        <Col xs="6" md="3">
                            <img src={dumbbell} alt="" />
                            <p className="big">Ćwiczenia ze sprzętem <br/>lub bez sprzętu</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={advice} alt="" />
                            <p className="big">Rady, wskazówki, <br/>motywacja</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={calendar} alt="" />
                            <p className="big">Kalendarz <br/>treningów</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={trainer} alt="" />
                            <p className="big">Opieka trenerów <br/> bez limitu</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs="6" md="3">
                            <img src={workoutWithAnn} alt="" />
                            <p className="big">Dostęp do <br/>wszystkich treningów</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={homeGym} alt="" />
                            <p className="big">Dostosowane do <br/>wykonania w domu</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={intensivity} alt="" />
                            <p className="big">Sama wybierasz <br/>intensywność</p>
                        </Col>
                        <Col xs="6" md="3">
                            <img src={workout} alt="" />
                            <p className="big">Różne <br/>poziomy trudności</p>
                        </Col>
                    </Row>
                </Container> 
            </section>

            <section className="hp17 py-0">
                <Container>
                    <Row className="align-items-center">
                        <Col md="6">
                            <GatsbyImage image={data.yoga.childImageSharp.gatsbyImageData} className="img-left" alt=""/>
                        </Col>
                        <Col md={{size: 5, offset: 1}}>
                            <h2>Zadbaj o <span className="pink">RÓWNOWAGĘ</span></h2>
                            <p>Wewnętrzna równowaga jest tym, co pozwala na systematyczne utrzymanie diety i aktywności fizycznej. Zobacz, jak zatroszczysz się o nią przy pomocy aplikacji!</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp18 py-0">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={{size: 12, order: 2}} md={{size: 5, order: 1}}>
                            <h2>Świadomy <span className="pink">oddech</span></h2>
                            <p>Oddech to jedno z najlepszych narzędzi do walki ze stresem: pomaga się uspokoić, skupić na chwili bieżącej lub opanować emocje. Właśnie dlatego oddech warto ćwiczyć i pracować nad tym, żeby był jak najbardziej świadomy.</p>
                            <p>W aplikacji znajdziesz 3 zestawy ćwiczeń, które Ci w tym pomogą: Skupienie, Spokój i Harmonia.</p>
                        </Col>
                        <Col xs={{size: 12, order: 1}} md={{size: 6, offset: 1, order: 2}}>
                            <div className="img-right text-center">
                                <Waves />
                                <GatsbyImage image={data.breathe.childImageSharp.gatsbyImageData} alt=""/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp19 pb-0">
                <Container>
                    <Row className="align-items-center">
                        <Col md="6">
                            <div className="img-left text-center">
                                <Waves />
                                <GatsbyImage image={data.balance.childImageSharp.gatsbyImageData} alt=""/>
                            </div>
                        </Col>
                        <Col md={{size: 5, offset: 1}}>
                            <h2>Balans</h2>
                            <p>Balans to wyjątkowe miejsce w aplikacji Diet &amp; Training by Ann wypełnione nagraniami prosto ze świata przyrody oraz autorską, relaksacyjną muzyką. Dzięki nim w dowolnym momencie możesz poczuć się tak, jakbyś właśnie spacerowała po lesie, siedziała przy ognisku lub była nad brzegiem morza. Przygotowaliśmy dla Ciebie aż 17 wyjątkowych ścieżek!</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp20 pt-0">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={{size: 12, order: 2}} md={{size: 5, order: 1}}>
                            <h2>Nawodnienie</h2>
                            <p>Odpowiednie nawodnienie jest jednym z kluczowych elementów odpowiadających za sprawną pracę Twojego ciała i umysłu: woda usuwa toksyny z organizmu, oczyszcza, dodaje energii i chęci do działania, orzeźwia, poprawia metabolizm, uśmierza ból, nawilża skórę. Korzystając z funkcji Nawodnienie będziesz mieć pewność, że dostarczasz swojemu organizmowi wystarczającą ilość wody.</p>
                        </Col>
                        <Col xs={{size: 12, order: 1}} md={{size: 6, offset: 1, order: 2}}>
                            <div className="img-right pt-5 text-center">
                                <Waves />
                                <GatsbyImage image={data.hydration.childImageSharp.gatsbyImageData} alt=""/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="hp21">
                <Container>
                    <h2 className="text-center mb-5"><span className="pink">Jedyna aplikacja</span>,<br/>której potrzebujesz</h2>
                    <GatsbyImage
                        image={data.advantages.childImageSharp.gatsbyImageData}
                        className="d-none d-md-block" alt=""/>
                    <GatsbyImage
                        image={data.advantagesMobile.childImageSharp.gatsbyImageData}
                        className="d-md-none" alt=""/>
                </Container>
            </section>

            <SaleProductsList
                title={false}
                productsGroups={productsGroups}
                source="mariola">
                    <h2 className="text-center">Wybierz pakiet i <span className="pink">zacznij dziś!</span></h2>
            </SaleProductsList>
        </Layout>
    );
}

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "homepage-new/trainers_main_screen.png" }) {...fluidImage1920}
        heroMobile: file(relativePath: { eq: "homepage-new/trenerzy_mobile.png" }) {...fluidImage960}
        ania: file(relativePath: { eq: "homepage-new/ania-test.png" }) {...fluidImage960}
        map: file(relativePath: { eq: "mariola/map.jpg" }) {...fluidBackground}
        foodPhoto: file(relativePath: { eq: "homepage-new/food-photo.jpeg" }) {...fluidImage960}
        trainers: file(relativePath: { eq: "homepage-new/trainers2-test.png" }) {...fluidImage1920}
        trainersMobile: file(relativePath: { eq: "homepage-new/trainers2-mobile.png" }) {...fluidImage960}
        programs: file(relativePath: { eq: "homepage-new/programs.png" }) {...fluidImage1920}

        threeMeals: file(relativePath: { eq: "homepage-new/3_meal_diet.jpg" }) {...fluidImage1920}
        programsA: file(relativePath: { eq: "homepage-new/programs-a.png" }) {...fluidImage1920}
        programsB: file(relativePath: { eq: "homepage-new/programs-b.png" }) {...fluidImage1920}

        anna: file(relativePath: { eq: "homepage-new/anna-test.png" }) {...fluidImage1920}
        ewelina: file(relativePath: { eq: "homepage-new/ewelina-test.png" }) {...fluidImage1920}
        mariola: file(relativePath: { eq: "homepage-new/mariola-test.png" }) {...fluidImage1920}
        kuba: file(relativePath: { eq: "homepage-new/kuba-test.png" }) {...fluidImage1920}
        jj: file(relativePath: { eq: "homepage-new/jj-test.png" }) {...fluidImage1920}

        phones: file(relativePath: { eq: "homepage-new/phones.jpeg" }) {...fluidImage1920}
        phones2: file(relativePath: { eq: "homepage/phones2.jpg"}) {...fluidImage1920}
        phone: file(relativePath: { eq: "homepage-new/phone.jpeg" }) {...fluidImage960}
        phone2: file(relativePath: { eq: "homepage-new/phone2.png" }) {...fluidImage960}
        phone2Mobile: file(relativePath: { eq: "homepage-new/phone2-mobile.png" }) {...fluidImage960}
        hydration: file(relativePath: { eq: "homepage-new/hydration.png" }) {...fluidImage960}
        balance: file(relativePath: { eq: "homepage-new/balance.png" }) {...fluidImage960}
        profile: file(relativePath: { eq: "homepage-new/profile.png" }) {...fluidImage960}
        breathe: file(relativePath: { eq: "homepage-new/breathe.png" }) {...fluidImage960}
        
        yoga: file(relativePath: { eq: "homepage-new/yoga.png" }) {...fluidImage960}

        programs2: file(relativePath: { eq: "homepage-new/programs2.png" }) {...fluidImage1920}
        programs2Mobile: file(relativePath: { eq: "homepage-new/programs2-mobile.png" }) {...fluidImage960}


        advantagesMobile: file(relativePath: { eq: "newyear2020/advantages2-mobile.png" }) {...fluidImage960}
        advantages: file(relativePath: { eq: "newyear2020/advantages2.png" }) {...fluidImage1920}
        advantagesMobile: file(relativePath: { eq: "newyear2020/advantages2-mobile.png" }) {...fluidImage960}
    }
`;
